
import imageVital from 'assets/img/diary/icon_box_vitals.png';
import imageVitalBP from 'assets/img/diary/vitalx_bloodpressure.png';
import imageVitalGlucose from 'assets/img/diary/vitalx_glucocheck.png';
import imageVitalHeartRate from 'assets/img/diary/vitalx_heartrate.png';
import imageVitalRespiration from 'assets/img/diary/vitalx_respiration.png';
import imageVitalSleep from 'assets/img/diary/vitalx_sleep.png';
import imageVitalSpO2 from 'assets/img/diary/vitalx_spo2.png';
import imageVitalTemperature from 'assets/img/diary/vitalx_temperature.png';
import imageVitalWeight from 'assets/img/diary/vitalx_weight.png';
import imageAdl from 'assets/img/diary/icon_box_adls.png';
import imageIntake from 'assets/img/diary/icon_box_intake.png';
import imageMental from 'assets/img/diary/icon_box_mental.png';
import imageGCS from 'assets/img/diary/icon_box_gcs.png';
import imageCondition from 'assets/img/diary/icon_box_conditions.png';
import imageNotes from 'assets/img/diary/icon_box_notes.png';
import imageMedical from 'assets/img/diary/icon_box_medication_v2.png';
import countryFlags from 'country-flag-emoji-json';

import komuneLogo from 'assets/img/logo/KomuneCare_Logo.png';
import careLogo from 'assets/img/logo/pdf_logo.png';
import theMansionLogo from 'assets/img/logo/themansion.png';

export const TimesheetStatus = {
  open: "open",
  inProgress: "in progress",
  completed: "completed",
  approved: "approved",
  done: "done",
  cancelled: "cancelled",
  rejected: "rejected"
}

export const JobStatus = {
  draft: "draft",
  open: "open",
  inProgress: "in-progress",
  completed: "completed",
  cancelled: "cancelled",
  closed: "closed"
}

export const OrderStatus = {
  // open: "open",
  // onGoing: "ongoing",
  // completed: "completed",
  // done: "done",
  // cancelled: "cancelled"
  draft: "draft",
  open: "open",
  financeToInvoice: "finance to invoice",
  invoiced: "invoiced",
  ongoing: "ongoing",
  completed: "completed",
  cancelled: "cancelled"
}

export const InvoiceStatus = {
  DRAFT: "DRAFT",
  SUBMITTED: "SUBMITTED",
  AUTHORISED: "AUTHORISED",
  DELETED: "DELETED",
  VOIDED: "VOIDED",
  PAID: "PAID"
}

export const PanicStatus = {
  open: "Open",
  handling: "Handling",
  resolved: "Resolved",
  cancelled: "Cancelled",
  resolvedAll: "Resolved All",
  cancelAll: "Cancel All"
}

export const RoleType = {
  facility: "facility",
  homecare: "homecare",
  corporate: "corporate",
  superadmin: "superadmin",
  finance: "finance",

  //only shows dashboard
  dashboard: "dashboard",

  //cannot login
  customer: "customer",
  caregiver: "caregiver",
  deployment: "deployment",
}

export const IntegrationType = {
  biztory: "biztory"
}

export const IntegrationUrl = {
  biztory: "mycareconcierge.biztory.com.my"
}

export const IntegrationPath = {
  biztoryCreateInvoice: "/api_v1/sale",
  biztoryCreateCustomer: "/api_v1/customer",
  biztoryCreateCaregiver: "/api_v1/vendor",
  biztoryCreateExpense: "/api_v1/expense"
}

export const PanicResolvedList = ["Can't Sleep", "Medication", "Toilet", "Move Around", "Hungry", "Make a Call", "Thirsty", "Others"];
export const PanicCanelledList = ["Misclick","Others"];

export const AppointmentStatusList = ["open", "completed", "cancelled"];

export const JobRoleList = ["caregiver", "nurse", "physiotherapy"];

export const GenderList = ["male", "female"];
export const MaritalStatusList = ["single", "married", "divorced", "widowed", "others"];
export const RaceList = ["malay", "chinese", "indian", "others"];
export const ReligionList = ["islam", "buddhism", "christianity", "hinduism", "others"];
export const ProductTypeList = ["service", "consumable", "daily services", "hourly services", "transport"];
export const ProductCategoryList = {
  "daily services": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "hourly services": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "transport": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "service": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "consumable": ["commerce", "komunecare", "residence", "academy"]
}
export const ProductSubCategoryList = {
  "homecare": ["caregiver", "nurse"],
  "mansion": [],
  "commerce": []
}
export const ProductStatusList = ['active', 'inactive'];

export const CentreList = [
  {
    id: 0,
    centreName: "Unassigned",
    label: "Unassigned",
    value: 0,
    isDisabled: false
  },
  {
    id: 1,
    centreName: "TM Gasing 136",
    label: "TM Gasing 136",
    value: 1,
    isDisabled: false
  },
  {
    id: 2,
    centreName: "TM Sri Aman",
    label: "TM Sri Aman",
    value: 2,
    isDisabled: false
  },
  {
    id: 3,
    centreName: "TM Gasing 60",
    label: "TM Gasing 60",
    value: 3,
    isDisabled: false
  },
  {
    id: 4,
    centreName: "Komune Care",
    label: "Komune Care",
    value: 4,
    isDisabled: false
  },
  {
    id: 5,
    centreName: "TPR Penang",
    label: "TPR Penang",
    value: 5,
    isDisabled: false
  },
  {
    id: 6,
    centreName: "HomeCare",
    label: "HomeCare",
    value: 6,
    isDisabled: false
  },
  {
    id: 7,
    centreName: "TM Ritchie",
    label: "TM Ritchie",
    value: 7,
    isDisabled: false
  },
  {
    id: 8,
    centreName: "Demo Company (Xero Development)",
    label: "Demo Company (Xero Development)",
    value: 8,
    isDisabled: false
  },
  {
    id: 9,
    centreName: "Angsana Home",
    label: "Angsana Home",
    value: 9,
    isDisabled: false
  },
  {
    id: 10,
    centreName: "Ara Woods",
    label: "Ara Woods",
    value: 10,
    isDisabled: false
  },
  {
    id: 11,
    centreName: "Jaya One Daycare",
    label: "Jaya One Daycare",
    value: 11,
    isDisabled: false
  },
  {
    id: 12,
    centreName: "Academy Care",
    label: "Academy Care",
    value: 12,
    isDisabled: false
  }
];

export const HomecareCentreFilter = [
  {
    id: 6,
    centreName: "HomeCARE"
  },
  {
    id: 0,
    centreName: "Unassigned"
  }
];

export const ZoneList = {
  //centre id: 1
  1: [
    {
      id: 1,
      zoneName: "S1"
    },
    {
      id: 2,
      zoneName: "S2"
    },
    {
      id: 3,
      zoneName: "S3"
    },
    {
      id: 4,
      zoneName: "S4"
    },
    {
      id: 5,
      zoneName: "S5"
    },
    {
      id: 6,
      zoneName: "S6"
    },
  ],
  //centre id: 2
  2: [
    {
      id: 7,
      zoneName: "S1 (Downstairs)"
    },
    {
      id: 8,
      zoneName: "S2 (Upstairs)"
    },
  ],
  //centre id: 3
  3: [
    {
      id: 9,
      zoneName: "58"
    },
    {
      id: 10,
      zoneName: "56"
    },
    {
      id: 11,
      zoneName: "54"
    },
  ]
};

export const YesNoOptions = [
  {
    id: 0,
    name: "No"
  },
  {
    id: 1,
    name: "Yes"
  }
];

export const MaintenanceStatusList = ["open", "in progress", "completed"];
export const ProfessionList = [
  "Artist",
  "Babysitter",
  "Butler",
  "Caregiver",
  "Chef",
  "Counsellor",
  "Dietitian",
  "Doctor",
  "Duty Manager",
  "Gardener",
  "Health Consultant",
  "Home chef",
  "Housekeeper",
  "Maid",
  "Manager",
  "Meal Planner",
  "Music therapist",
  "Nanny",
  "Nurse",
  "Nutritionist",
  "Performer",
  "Physiotherapist",
  "Program coordinator",
  "Retired Doctor",
  "Retired Nurse",
  "Retired therapist",
  "Singing Companion",
  "Traditional Chinese Medicine Doctor",
  "Traditional Chinese Medicine Health Pro",
  "Assistant Media Officer",
  "Occupational Therapist",
  "Chiropractor",
  "Intern (caregiver)",
  "Intern ( Medical Doctor)",
  "Intern (Staff Nurse)",
  "Intern ( Assistant Medical Officer)",
  "Clinical Instructor",
  "Trainer",
  "Program Director",
];
export const CaregiverStatusList = ["new", "pending verification", "active", "assessment kiv", "blacklisted", "disabled"];
export const CaregiverSourceList = ['Came for interview', 'Ex-work colleague', 'Facebook', 'Family', 'Flyer', 'Friend of friends', 'Friends', 'Instagram', 'Job portal', 'LinkedIn', 'Newspaper/Magazine', 'Others', 'Personal connection', 'Website'];
export const OrderStatusList = ["draft", "open", "finance to invoice", "invoiced", "ongoing", "completed", "cancelled"];

export const AllowanceList = [
  // "Transportation",
  "Meal Allowance",
  "Overtime",
  "COVID Test Kit",
  "Others (Please specify)"
];

export const PaymentStatus = {
  processed: "processed"
}

export const renderedStatus ={
  approve : "APPROVE",
  rejected: "REJECTED",
  cancel: "CANCEL"
}

export const UserType = {
  customer: "customer",
  care_pro: "care pro",
  careadmin: "careadmin"
}

export const App = {
  caremanagerportal: "Care Manager Portal",
  jomcare: "Jomcare"
}

const centreMap = CentreList.reduce((acc, centre) => {
  acc[centre.id] = centre.centreName;
  return acc;
}, {});

export const searchCentre = (_id) => {
  return centreMap[_id] || "-";
};

export const MaintenancePriorityList = [
  {
    id: 1,
    priority: "Critical"
  },
  {
    id: 2,
    priority: "High"
  },
  {
    id: 3,
    priority: "Medium"
  },
  {
    id: 4,
    priority: "Low"
  }
];

export const IssuesCategoryList = [
  {
    id: "credit_note",
    category: "Credit Note (CN)"
  },
  {
    id: "change_request",
    category: "Change Request (CR)"
  },
  {
    id: "cancel_order",
    category: "Cancel Order"
  },
  {
    id: "discount_request",
    category: "Discount Request"
  },
  {
    id: "payment_query",
    category: "CG Payment Query (Finance)"
  },
  {
    id: "error",
    category: "Error Detected (Finance)"
  }
];

export const IssuesCategoryListOptions = IssuesCategoryList.map((_) => {return {value: _.id, label: _.category}})

const issuesCategoryHashMap = IssuesCategoryList.reduce((map, item) => {
  map[item.id] = item;
  return map;    
}, {});

export const searchIssueCategoryById = (id) => {
  return issuesCategoryHashMap[id] || {};
};

export const MaintenanceStatus = {
  open: "open",
  inProgress: "in progress",
  completed: "completed"
};

export const WorkType = ["Full Time", "Part Time"];

export const DiaryType = [
  'weight', 
  'glucose', 
  'heartrate', 
  'bloodpressure', 
  'sleep', 
  'temperature', 
  'note', 
  'spo2', 
  'adl', 
  'pain', 
  'input', 
  'output', 
  'mental', 
  'respiratory', 
  'gcs', 
  'medication_usage'
];

export const RoomServicesList = [
  {value: "alu", label: "ALU"},
  {value: "daycare", label: "Daycare"},
]

export const DiaryNameMapper = {
  weight: 'Weight', 
  glucose: 'Glucose', 
  heartrate: 'Heart Rate', 
  bloodpressure: 'Blood Pressure', 
  sleep: 'Sleep', 
  temperature: 'Temperature', 
  note: 'Note', 
  spo2: 'SPO2', 
  adl: 'ADLs', 
  pain: 'Pain', 
  input: 'Input', 
  output: 'Output', 
  mental: 'Mental', 
  respiratory: 'Respiratory', 
  gcs: 'Gcs', 
  medication_usage: 'Medication_usage'
};

export const DiaryImageMapper = {
  weight: imageVitalWeight,
  sleep: imageVitalSleep,
  temperature: imageVitalTemperature,
  bloodpressure: imageVitalBP,
  heartrate: imageVitalHeartRate,
  glucose: imageVitalGlucose,
  spo2: imageVitalSpO2,
  respiratory: imageVitalRespiration,
  adl: imageAdl,
  input: imageIntake,
  output: imageIntake,
  mental: imageMental,
  gcs: imageGCS,
  pain: imageCondition,
  note: imageNotes,
  medication_usage: imageMedical,
};

export const DiaryUnitMapper = {
  weight: "kg",
  sleep: "hours",
  temperature: "\u2103",
  bloodpressure: "mmHg",
  heartrate: "bpm",
  glucose: "mmol/L",
  spo2: "%",
  respiratory: "bpm",
};

export const gcsEye = ["Spontaneously", "To Voice", "To Pain", "No Response"];

export const gcsMotor = [
  "Orientated To Time, Place and Person",
  "Confused",
  "Inappropriate Words",
  "Incomprehensible Sounds",
  "No Response",
];

export const gcsVerbal = [
  "Obeys Commands",
  "Localizes Pain",
  "Withdrawal Form Pain",
  "Abnormal Flexion (Decorticate)",
  "Abnormal Extension (Decerebrate)",
  "No Response",
];

export const emojiMapper = {
  "11":"Content",
  "10":"Playful",
  "9":"Verry Happy",
  "8":"Happy",
  "7":"Restless",
  "6":"Sick",
  "5":"Angry",
  "4":"Annoyed",
  "3":"Confused",
  "2":"Sleepy",
  "1":"Upset",
  "0":"Sad",
};

export const inputMealList = ["1", "2", "3", "4", "5", "6"];
export const inputMealMapper = {
  "1":"Breakfast",
  "2":"Lunch",
  "3":"Tea Time",
  "4":"Dinner",
  "5":"Fruits",
  "6":"Snack",
}

export const inputTypeList = ["1", "2", "3"];
export const inputTypeMapper = {
  "1":"Normal",
  "2":"NGT",
  "3":"Peg Tube",
}

export const outputSimpleMapper = {
  1: "stool",
  2: "urine"
}
export const outputMealMapper = {
  1: {
    "name": "stool",
    "type": {
      1: "Normal",
      2: "Diaper",
      3: "Colostomy",
      4: "Ileostomy",
    },
    "amount": {
      1: "Large",
      2: "Moderate",
      3: "Small",
    },
    "color": {
      1: "Black",
      2: "Brown",
      3: "Light Brown",
      4: "Green",
      5: "Red",
      6: "Pink/Purple",
      7: "Grey",
      8: "Yellow",
      9: "Orange",
    },
    "pattern": {
      1: "Type 1 Very constipated Hard Lumps",
      2: "Type 2 Slightly constipated Lumpy & sausage",
      3: "Type 3 Sausage shape Crack surface",
      4: "Type 4 Soft sausage Snake, Normal",
      5: "Type 5 Soft blobs Clear-cut edges",
      6: "Type 6 Mushy consistency Ragged edges",
      7: "Type 7 Liquid consistency No solid pieces",
    }
  },
  2: {
    "name": "urine",
    "type": {
      1: "Normal",
      2: "Diaper",
      3: "CBD",
      4: "Urostomy",
    },
    "amount": {
      1: "Large",
      2: "Moderate",
      3: "Small",
    },
    "color": {
      1: "Clear",
      2: "LightYellow",
      3: "Yellow",
      4: "DarkYellow",
      5: "Amber",
      6: "Brown",
      7: "Red",
    },
    "pattern": {
    }
  }
}

export const adlList = [
  "activity",
  "ambulation",
  "bathing",
  "bedbath",
  "changeclothes",
  "changelinen",
  "exercise",
  "facemassage",
  "facial",
  "feedfood",
  "haircut",
  "headmassage",
  "massage",
  "nailcare",
  "shaving",
  "toiletassist",
  "turning",
  "diaperchange",
  "feeding",
  "hairshampoo",
  "footmassage",
  "putlotion",
  "lightstretching",
]

export const adlMapper = {
  "activity": "Activity",
  "ambulation": "Ambulation",
  "bathing": "Bathing",
  "bedbath": "Bed Bath",
  "changeclothes": "Change Clothes",
  "changelinen": "Change Linen",
  "exercise": "Exercise",
  "facemassage": "Face Massage",
  "facial": "Facial",
  "feedfood": "feed food",
  "haircut": "haircut",
  "headmassage": "head massage",
  "massage": "massage",
  "nailcare": "nailcare",
  "shaving": "shaving",
  "toiletassist": "toiletassist",
  "turning": "turning",
  "diaperchange": "diaperchange",
  "feeding": "feeding",
  "hairshampoo": "hairshampoo",
  "footmassage": "foot massage",
  "putlotion": "put lotion",
  "lightstretching": "light stretching"
}

export const painTypeList = [
  "no pain",
  "mild",
  "moderate",
  "severe pain",
  "very severe pain",
  "worst possible"
]

export const painBodyList = [  
  "head",
  "left neck",
  "right neck",
  "left shoulder",
  "right shoulder",
  "armpit",
  "chest ",
  "abdomen",
  "elbow",
  "upper arm",
  "lower arm",
  "hand",
  "private",
  "groin",
  "knee",
  "lower leg",
  "upper leg",
  "foot",
  "toes",
]

export const foodList = [  
  "Biscuit",
  "Bread",
  "Croissant",
  "Cereal",
  "Oat",
  "Coffee",
  "Egg",
  "Soup Noodle",
  "Fried Noodle",
  "Fried Rice",
  "Nasi Lemak",
  "Green Tea",
  "Horlicks",
  "Milo",
  "Tea",
  "Teh Tarik",
  "Mango Juice",
  "Orange Juice",
  "Red Apple",
  "Green Apple",
  "Orange",
  "Banana",
  "Lemon",
  "Mango",
  "Watermelon",
  "Pineapple",
  "Pumpkin",
  "Cake",
  "Cupcake",
  "Donut",
  "Puff",
  "Cone Icecream",
  "Stick Icecream",
  "Mushroom Soup",
  "Pumpkin Soup",
  "Red Bean Soup",
  "Porridge",
  "Salad Bowl",
  "Coke",
  "Pepsi",
  "Chicken",
  "Curry Fish",
  "Steam Fish",
  "Few Water",
  "Half Water",
  "Full Water",
  "Milk",
  "Rice",
  "Meat",
  "Pork",
  "Fried Egg",
  "Omelette",
  "Ulam",
  "Vegetables",
]

export const inputUnitsList = [
  "unit", "l", "ml"
] 

export const ancillaryServicesCategory = [
  "Medicine",
  "Medical Supply",
  "Miscellaneous",
  "Physiotherapy & Other Therapy",
  "Escort & Chaperone",
  "Transportation & Logistics",
]

export const cgRateCalculator = (_timeSheet) => {
  if((_timeSheet ?? undefined) === undefined)
    return undefined;
  if("cg_rate" in _timeSheet && "start_time" in _timeSheet && "end_time" in _timeSheet && "product_type" in _timeSheet){
    return cgRateCalculatorRaw(
      _timeSheet?.cg_rate ?? 0.00, 
      _timeSheet?.product_type, 
      _timeSheet?.start_time, 
      _timeSheet?.end_time);
  }else{
    alert("Timesheet error not contain cg_rate, start_time, end_time OR product_type");
  }
  return NaN;
}
export const cgRateCalculatorRaw = (cg_rate, product_type, start_time, end_time) => {
  
  function getTotalSeconds(start, end) {
    if(start === "" || start === undefined || end === "" || end === undefined )
      return 0;
    const startArray = start.split(":");
    const endArray = end.split(":");
    if(startArray.length < 2 && endArray.length < 2 )
      return 0;
    
    const startSecond = (Number(startArray[0])*60*60) + (Number(startArray[1])*60);
    let endSecond = (Number(endArray[0])*60*60) + (Number(endArray[1])*60);
    if(endSecond < startSecond || endSecond == startSecond){
      endSecond += 86400
    }
    return endSecond - startSecond;
  }  

  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return { h: hours, m: minutes, s: seconds };
  }  

  if (product_type === "comsumable" || product_type === "consumable"){
    return cg_rate ? parseFloat(cg_rate) : 0.00;
  }else if (product_type === "hourly services"){
    const sec = getTotalSeconds(start_time, end_time);
    const totalHours = toHoursAndMinutes(sec);
    return (
      cg_rate?
      (totalHours.h * parseFloat(cg_rate)) + (totalHours.m * parseFloat(cg_rate) / 60) : 0.00
    );
  }else if (product_type === "service" || product_type === "daily services"){
    return cg_rate ? parseFloat(cg_rate) : 0.00;
  }
}

export const tryDecodeURI = (text) => {
  let decodedValue = text;
  try {
    decodedValue = decodeURI(text);
  } catch (error) {
    // If decoding fails, keep the original text
  }
  return decodedValue; 
};

export const getNextMonthFirstDay = (date) => {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return nextMonth;
};

export const getNextMonthLastDay = (date) => {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  return nextMonth;
}

export const getRawPhoneNumber = (mobile) => {
  if(mobile === null) return null;
  const rawMobile = mobile.replaceAll(/\D/g,'');
  const PHONE_REGEX3 = /^01[0-9]*$/
  if(PHONE_REGEX3.test(rawMobile)){
    return '+6' + rawMobile;
  }
  return '+' + rawMobile;
}

export const EmploymentType = [
  "Local", 
  "F - RTK2.0", 
  "F - Kosong", 
  "F - Refugee", 
  "F - Cleaner Outsource", 
  "F - Other Visa", 
  "F - CCR", 
  "PT / Locum", 
  "Spouse Visa",
  "Permanent Resident"];

export const bankNameList = [
  "Evolet",
  "Jompay",
  "InstaPay",
  "Bank Rakyat Berhad",
  "Bangkok Bank Berhad",
  "Bank of America",
  "AmBank Berhad",
  "Bank Simpanan Nasional Berhad",
  "Alliance Bank Berhad",
  "Standard Chartered Bank Berhad",
  "Citibank",
  "RHB Bank Berhad",
  "Deutsche Bank (M) Bhd",
  "HSBC Bank Berhad",
  "Hong Leong Bank Berhad/ Hong Leong Finance",
  "United Overseas Bank (M) Bhd",
  "Malayan Banking Berhad",
  "OCBC Bank Berhad",
  "Affin Bank Berhad",
  "Public Bank Berhad/ Public Finance Berhad",
  "CIMB Bank Berhad",
  "Bank Muamalat Malaysia Berhad",
  "Bank of China (Malaysia) Berhad",
  "Bank Islam Malaysia Berhad",
  "The Royal Bank of Scotland Bhd",
  "Kuwait Finance House",
  "JP Morgan Chase",
  "Agro Bank",
  "Sumitomo Mitsui Banking Corporation (M) Bhd",
  "Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad",
  "Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad",
  "Indust & Comm Bank of China (M) Berhad",
  "BNP Paribas (M) Bhd / BNP Paribas (Islamic)",
  "China Construction Bank (Malaysia) Berhad",
  "Mizuho Bank (M) Berhad",
  "MBSB"
]


export const RelationshipAccessLevel = {
  PENDING_APPROVAL: 0,
  VIEWER: 1,
  EDITOR: 2,
  ADMIN: 3,
  CARE_MASTER: 98,
  CARE_OWNER: 99,
};

export const RelationshipAccessLevelDefinition = {
  0: {id: 0, name: "pendding", text: "Pendding Approve", desc: "The relationship status not approved."},
  1: {id: 1, name: "read", text: "Viewer", desc: "View all the customer details."},
  2: {id: 2, name: "write", text: "Editor", desc: "Can edit the customer all data details."},
  3: {id: 3, name: "admin", text: "Admin", desc: "Admin able Manipulation the other relationship changes"},
  98: {id: 98, name: "master", text: "Care Master", desc: "Care Master, and Payee of the customer"},
  99: {id: 99, name: "owner", text: "Care Master owner", desc: "Care Owner, Self Owner, Care Master, and Payee"},
}

export const relationshipIds = {
  1: 'Mother',
  2: 'Father',
  3: 'Daughter',
  4: 'Son',
  5: 'Sister',
  6: 'Brother',
  7: 'Aunt',
  8: 'Uncle',
  9: 'Cousin (Female)',
  10: 'Cousin (Male)',
  11: 'Grandmother',
  12: 'Grandfather',
  13: 'Niece',
  14: 'Nephew',
  15: 'Stepfather',
  16: 'Stepmother',
  17: 'Stepdaughter',
  18: 'Stepson',
  19: 'Sister-in-law',
  20: 'Brother-in-law',
  21: 'Mother-in-law',
  22: 'Father-in-law',
  23: 'Granddaughter',
  24: 'Grandson',
  25: 'Great-grandmother',
  26: 'Great-grandfather',
  27: 'Daughter-in-law',
  28: 'Son-in-law',
  29: 'Wife',
  30: 'Husband',
  31: 'Other',
};

export const relationships = {
  1: [4, 3, 31],
  2: [4, 3, 31],
  3: [1, 2, 31],
  4: [1, 2, 31],
  5: [6, 31],
  6: [5, 31],
  7: [13, 14, 31],
  8: [13, 14, 31],
  9: [10, 31],
  10: [9, 31],
  11: [24, 23, 31],
  12: [24, 23, 31],
  13: [7, 8, 31],
  14: [7, 8, 31],
  15: [17, 18, 31],
  16: [17, 18, 31],
  17: [15, 16, 31],
  18: [15, 16, 31],
  19: [20, 31],
  20: [19, 31],
  21: [27, 28, 31],
  22: [27, 28, 31],
  23: [11, 12, 31],
  24: [11, 12, 31],
  25: [26, 24, 31],
  26: [25, 24, 31],
  27: [21, 22, 31],
  28: [21, 22, 31],
  31: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 31],
};

// Convert relationship data to select options
export const relationshipOptions = Object.keys(relationshipIds).map(id => ({
  value: id,
  label: relationshipIds[id]
}));

export const marketingChannelList = [
  {
    label: "Marketing - Meta",
    value: "Marketing - Meta"
  },
  {
    label: "Marketing - Google Form",
    value: "Marketing - Google Form"
  },
  {
    label: "Marketing - Others",
    value: "Marketing - Others"
  },
  {
    label: "Business Development",
    value: "Business Development"
  },
  {
    label: "SBU",
    value: "SBU"
  },
]

export const isFacilityDef = (_order) => {
  return !((_order?.centre ?? _order?.center ?? 0) === 6);
};
 // List of country codes to pin at the top
 const pinnedCodes = ["MY", "SG", "CN", "PH", ];

 // Generate nationality options with pinned items
 export const NationalityOptions = [
   // First, map over pinned codes and extract the matching countries
   ...pinnedCodes
     .map((code) => countryFlags.find((country) => country.code === code))
     .filter(Boolean) // Remove any undefined matches
     .map((country) => ({
       value: country.name,
       label: `${country.emoji} ${country.name}`,
     })),
   // Then, add the rest of the countries that are not pinned
   ...countryFlags
     .filter((country) => !pinnedCodes.includes(country.code)) // Exclude pinned countries
     .map((country) => ({
       value: country.name,
       label: `${country.emoji} ${country.name}`,
     })),
 ];

 export const timesheetStatusList = [
  {
    label: "Open",
    value: "open"
  },
  {
    label: "Approved",
    value: "approved"
  },
  {
    label: "Rejected",
    value: "rejected"
  },
  {
    label: "Cancelled",
    value: "cancelled"
  },
  {
    label: "Completed",
    value: "completed"
  }
];

export const orderStatusColorHash = {
  "draft": "",
  "open": "quaternary",
  "finance to invoice": "tertiary",
  "invoiced": "secondary",
  "ongoing": "primary",
  "completed": "success",
  "cancelled": "danger"
}

export const CompanyHash = {
	"1435752V" :{		
		templateName: "Care Guardian",
		companyName: "Care Guardian Sdn Bhd",
		companyCode: "1435752V",
		companyLogo: careLogo,
		companyAddress1: "A-1-2, Level 2, Block A, Jaya One, Jalan Profesor Diraja Ungku Aziz",
		companyAddress2: "46200 Petaling Jaya, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: "contact@mycareconcierge.com",
		website: "http://www.mycareconcierge.com",
		tncUrl: "https://www.mycareconcierge.com/",
		bankType: "CIMB BANK",
		companyBankAccount: "8010948703",
		companyBankName: "CARE GUARDIAN SDN BHD",
		externalNote: 
`For payment via Fund Transfer, kindly provide the bank in slip via email to sales@mycareconcierge.com or to your Care
Manager. All payments are to be made prior to the commencement of any caregiving services. If you have any questions
regarding this invoice, please contact your Care Manager.

We reserve the right to charge 1.5% interest per month on the invoiced amount for overdue invoices
`,
		tax: 0,
	},
	"642089-X":{		
		templateName: "Ritchie",
		companyName: "Sawara Ampang Care Centre Sdn Bhd", 
		companyCode: "642089-X",
		companyLogo: theMansionLogo,
		companyAddress1: "No 333, Persiaran Ritchie, Desa Pahlawan",
		companyAddress2: "55000 Kuala Lumpur, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: "contact@mycareconcierge.com",
		website: "https://www.themansion.com.my",
		tncUrl: "https://www.mycareconcierge.com/",
		companyBankAccount: "8010721544",
		bankType: "CIMB BANK",
		companyBankName: "SAWARA AMPANG CARE CENTRE SDN. BHD.",
		externalNote:
`For payment via Fund Transfer, kindly provide the bank in slip or sales invoice number on recipient's reference, via email to
finance.invoice@mycareconcierge.com
Kindly make all payments within 7 days from the date of this invoice. If you have any questions, please do not hesitate to contact
us.
We reserve the right to change 1.5% interest per month on the invoiced amount for overdue invoices.
`,
		tax: 0,
	},
	"202101014451":{		
		templateName: "Komune Care",
		companyName: "Komune Care Centre Sdn Bhd", 
		companyCode: "202101014451",
		companyLogo: komuneLogo,
		companyAddress1: "Komune Living & Wellness, 21, Jalan Tasik Permaisuri 2",
		companyAddress2: "Bandar Tun Razak, 56000 Kuala Lumpur, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: " contact@mycareconcierge.com",
		website: "http://www.komunecare.com",
		tncUrl: "",
		companyBankAccount: "8010839356",
		bankType: "CIMB BANK",
		companyBankName: "KOMUNE CARE CENTRE SDN BHD",
		externalNote:
`For payment via Fund Transfer, kindly provide the bank in slip or sales invoice number on recipient's reference, via email to
finance.komunecare@mycareconcierge.com
Kindly make all payments within 7 days from the date of this invoice. If you have any questions, please do not hesitate to contact
us.
We reserve the right to change 1.5% interest per month on the invoiced amount for overdue invoices.
`,
		tax: 0,
	},
  "1214920-M":{		
		templateName: "Sawara Care",
		companyName: "Sawara Care Centre Sdn. Bhd.", 
		companyCode: "1214920-M",
		companyLogo: theMansionLogo,
		companyAddress1: "136, Jalan Gasing,",
		companyAddress2: "46000 Petaling Jaya, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: " contact@mycareconcierge.com",
		website: "http://www.themansion.com",
		tncUrl: "",
		companyBankAccount: "8008453738",
		bankType: "CIMB BANK",
		companyBankName: "SAWARA CARE CENTRE SDN. BHD.",
		externalNote:
`For payment via Fund Transfer, kindly provide the bank in slip or sales invoice number on recipient's reference, via email to
finance.invoice@mycareconcierge.com
Kindly make all payments within 7 days from the date of this invoice. If you have any questions, please do not hesitate to contact
us.
We reserve the right to change 1.5% interest per month on the invoiced amount for overdue invoices.
`,
		tax: 0,
	},
  "1325156-A":{		
		templateName: "Sawara Spring Care",
		companyName: "Sawara Spring Care Centre Sdn. Bhd.", 
		companyCode: "1325156-A",
		companyLogo: theMansionLogo,
		companyAddress1: "58, Jalan Gasing, Bukit Gasing,",
		companyAddress2: "46000 Petaling Jaya, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: " contact@mycareconcierge.com",
		website: "http://www.themansion.com",
		tncUrl: "",
		companyBankAccount: "8010015592",
		bankType: "CIMB BANK",
		companyBankName: "SAWARA SPRING CARE CENTRE SDN. BHD.",
		externalNote:
`For payment via Fund Transfer, kindly provide the bank in slip or sales invoice number on recipient's reference, via email to
finance.invoice@mycareconcierge.com
Kindly make all payments within 7 days from the date of this invoice. If you have any questions, please do not hesitate to contact
us.
We reserve the right to change 1.5% interest per month on the invoiced amount for overdue invoices.
`,
		tax: 0,
	},
  "1435751U":{		
		templateName: "Care Resources",
		companyName: "CARE CONCIERGE RESOURCES SDN BHD", 
		companyCode: "1435751U",
		companyLogo: careLogo,
		companyAddress1: "A-1-2, Block A, Jaya One, Jalan Profesor Diraja Ungku Aziz,",
		companyAddress2: "46200 Petaling Jaya, Malaysia",
		telephone: "1300-22-8822 (Hotline)",
		email: " contact@mycareconcierge.com",
		website: "http://www.themansion.com",
		tncUrl: "",
		companyBankAccount: "8010946747",
		bankType: "CIMB BANK",
		companyBankName: "CARE CONCIERGE RESOURCES SDN BHD",
		externalNote:
`For payment via Fund Transfer, kindly provide the bank in slip or sales invoice number on recipient's reference, via email to
finance@mycareconcierge.com
Kindly make all payments within 7 days from the date of this invoice. If you have any questions, please do not hesitate to contact
us.
We reserve the right to change 1.5% interest per month on the invoiced amount for overdue invoices.
`,
		tax: 0,
	},
}

export const CenterHashCompanyList = {
  1: CompanyHash["1214920-M"], // Sawara Care Centre
  2: CompanyHash["1325156-A"], // Sawara Spring Care Centre
  3: CompanyHash["1325156-A"], // Sawara Spring Care Centre
  4: CompanyHash["202101014451"], // Komune CARE
  5: CompanyHash["1214920-M"], // Sawara Care Centre
  6: CompanyHash["1435752V"], // CARE Guaardian
  7: CompanyHash["642089-X"], // Sawara Ampang Care Centre
  9: CompanyHash["1214920-M"], // Sawara Care Centre
  10: CompanyHash["1214920-M"], // Sawara Care Centre
  11: CompanyHash["1214920-M"], // Sawara Care Centre
  12: CompanyHash["1435751U"], // Care Concierge Resources Sdn. 
}

export const CareCompanyList = Object.values(CompanyHash);

export const ProformaInvoiceInfo = {
  item: "proforma_invoice",
  pretext: "PI-HC"
}

export const processImageData = (dataUrl) => {
  
    // 1. Parse out the MIME type and Base64 content.
    //    dataUrl format: "data:<mimeType>;base64,<encodedString>"
    const match = dataUrl.match(/^data:(.*?);base64,(.*)$/);
    if (!match) {
      console.warn(`Invalid Data URL at : ${dataUrl}`);
      return undefined;
    }
    const mimeType = match[1];

    // 5. Determine a file extension from the MIME type (optional).
    let extension = "";
    if (mimeType === "image/png") {
      extension = ".png";
    } else if (mimeType === "image/jpeg") {
      extension = ".jpeg";
    } else if (mimeType === "image/gif") {
      extension = ".gif";
    } else if (mimeType === "image/svg+xml") {
      extension = ".svg";
    } else if (mimeType === "image/x-icon") {
      extension = ".ico";
    } 

    return {mimeType, extension}
}

/**
 * Converts an array of Base64 Data URLs into an array of JS File objects
 * in a format similar to:
 * 
 * [
 *   File {
 *     name: "image_1.png",
 *     lastModified: 1740388434642,
 *     size: 12345,
 *     type: "image/png",
 *     preview: "blob:http://localhost:3000/...",
 *     ...
 *   },
 *   ...
 * ]
 *
 * @param {string[]} dataUrls - Array of Data URLs, e.g. ["data:image/png;base64,iVBORw0K...", ...].
 * @returns {Promise<File[]>}
 */
export async function convertBase64ImagesToJsFiles(dataUrls) {
  const filesArray = [];

  for (let i = 0; i < dataUrls.length; i++) {
    const dataUrl = dataUrls[i];

    // 1. Parse out the MIME type and Base64 content.
    //    dataUrl format: "data:<mimeType>;base64,<encodedString>"
    const match = dataUrl.match(/^data:(.*?);base64,(.*)$/);
    if (!match) {
      console.warn(`Invalid Data URL at index ${i}: ${dataUrl}`);
      continue;
    }
    const mimeType = match[1];
    const base64Data = match[2];

    // 5. Determine a file extension from the MIME type (optional).
    let extension = "";
    if (mimeType === "image/png") {
      extension = ".png";
    } else if (mimeType === "image/jpeg") {
      extension = ".jpeg";
    } else if (mimeType === "image/gif") {
      extension = ".gif";
    } else if (mimeType === "image/svg+xml") {
      extension = ".svg";
    } else if (mimeType === "image/x-icon") {
      extension = ".ico";
    } 
    // 2. Decode Base64 string to binary.
    const byteString = atob(base64Data);
    const byteArray = new Uint8Array(byteString.length);

    for (let j = 0; j < byteString.length; j++) {
      byteArray[j] = byteString.charCodeAt(j);
    }

    // 3. Create a Blob from the binary data.
    const blob = new Blob([byteArray], { type: mimeType });

    // 4. Create a local preview URL for the blob (used for <img src>, etc.).
    const previewUrl = URL.createObjectURL(blob);

    // ...add more conditions if needed

    // 6. Create a JavaScript File object, including lastModified.
    //    (You can adjust lastModified to any Date value you want.)
    const fileName = `image_1${extension}`; // Or any name pattern
    const file = new File([blob], fileName, {
      type: mimeType,
      lastModified: Date.now()
    });

    // 7. Attach any custom properties you need on the File object.
    file.preview = previewUrl;  // e.g., a local preview for UI
    // file.path = "logo.ico";   // if you want to store an original 'path' name

    filesArray.push(file);
  }

  return filesArray;
}

export const extractImagesFromHtml = (html, getPrefix, hash = {}) => {
  const imageData = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Pre-compute a mapping from normalized src to hash value.
  const hashMapping = {};
  for (const key in hash) {
    if (hash.hasOwnProperty(key)) {
      // Parse the key HTML to extract the <img> element.
      const keyDoc = parser.parseFromString(key, "text/html");
      const keyImg = keyDoc.querySelector("img");
      if (keyImg) {
        // Use the src attribute from the parsed key.
        hashMapping[keyImg.src] = hash[key];
      }
    }
  }

  // Query all <img> elements in the document.
  const images = doc.querySelectorAll("img");
  var count = 0;
  images.forEach((img, index) => {
    // Save the src attribute to the imageData array.
    imageData.push(img.src);

    // Create a custom element to replace the <img> tag.
    const customElement = doc.createElement("cusimg");

    // Check if the normalized src exists in our hash mapping.
    if (hashMapping.hasOwnProperty(img.src)) {
      customElement.textContent = hashMapping[img.src];
    } else {
      const _data = processImageData(img.src);
      if(_data !== undefined){
        const { mimeType, extension } = _data;
        customElement.textContent = `[${getPrefix && getPrefix(count)}/image_1${extension}]`;
        ++count;
      }
    }

    // Replace the <img> tag with the custom element.
    img.parentNode.replaceChild(customElement, img);
  });

  // Serialize the modified document back to an HTML string.
  const processedHtml = doc.body.innerHTML;
  return { processHtml: processedHtml, imageData };
};


/**
 * Parses a HubSpot CRM URL and extracts specific components from it.
 *
 * @param {string} url - The HubSpot URL to parse. Must be a string.
 * @returns {Object} An object containing the parsing result:
 *   - `completed` {boolean}: Indicates whether the parsing was successful.
 *   - `message` {string}: A message describing the result of the parsing.
 *   - `item` {string} (optional): The item type (e.g., "contacts") if parsing is successful.
 *   - `number` {string} (optional): The portal ID if parsing is successful.
 *   - `component` {string} (optional): The component type (e.g., "record") if parsing is successful.
 *   - `recordUrlType` {string} (optional): The URL type (e.g., record type) if parsing is successful.
 *   - `dealID` {string} (optional): The Deal ID (e.g., record id) if parsing is successful.
 */
export const parseHubspotUrl = (url) => {
  if (typeof url !== 'string') {
    console.warn('The provided URL must be a string.');
    return { completed: false, message: 'The provided URL must be a string.' }; 
  }

  const regex = /^https:\/\/app\.hubspot\.com\/([^\/]+)\/(\d+)\/([^\/]+)\/([0-9A-Za-z\-]+)\/([0-9]+)\/?$/;
  const match = url.match(regex);

  if (!match) {
    console.warn('The URL does not match the expected HubSpot CRM URL format.');
    return { completed: false, message: 'The URL does not match the expected HubSpot CRM URL format.' };
  }

  // Destructure the matched groups:
  // match[1] -> item ("contacts")
  // match[2] -> number (portal id)
  // match[3] -> component ("record")
  // match[4] -> URL type (record type)
  // match[5] -> record id (not used as per requirements)
  return {
    completed: true,
    message: 'Completed',
    item: match[1],
    number: match[2],
    component: match[3],
    recordUrlType: match[4],
    dealID: match[5],
  };
}