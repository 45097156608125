// import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
// import SourceLink from 'components/SourceLink';
import React, {useState} from 'react';
//import { FaGithub } from 'react-icons/fa';
import {
  MdBorderAll,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdKeyboardArrowDown,
  MdLockOpen,
  MdPeople,
  MdRadioButtonChecked,
  MdStar,
  MdTextFields,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdOutlineSimCardAlert
} from 'react-icons/md';

import { IoMdArrowBack } from 'react-icons/io';
import { Link, NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Col
} from 'reactstrap';
import bn from 'utils/bemnames';
import { AuthContext, ProfileContext } from 'utils/contexts';
import { RoleType } from 'utils/constants';
import * as RiIcons from "react-icons/ri";
import styled from 'styled-components';
import packageJson from '../../../package.json';
import { BsAlarmFill, BsBellFill } from 'react-icons/bs';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

// const navComponents = [
//   { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
//   {
//     to: '/button-groups',
//     name: 'button groups',
//     exact: false,
//     Icon: MdGroupWork,
//   },
//   { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
//   { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
//   {
//     to: '/dropdowns',
//     name: 'dropdowns',
//     exact: false,
//     Icon: MdArrowDropDownCircle,
//   },
//   { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
//   { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
//   { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
//   { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
// ];

// const pageContents = [
//   { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
//   {
//     to: '/login-modal',
//     name: 'login modal',
//     exact: false,
//     Icon: MdViewCarousel,
//   },
// ];

// const navItems = [
//   { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
//   { to: '/cards', name: 'cards', exact: false, Icon: MdWeb },
//   { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
//   { to: '/widgets', name: 'widgets', exact: false, Icon: MdWidgets },
// ];

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard, 
    role: [RoleType.dashboard, RoleType.superadmin, RoleType.corporate],
    showDashboard : 1
  },
];


const navSettingItems = [
  { 
    to: '/settings/changepassword', name: 'change password', exact: true, Icon: MdWeb,
    role: [RoleType.dashboard, RoleType.superadmin, RoleType.corporate, RoleType.facility, RoleType.homecare, RoleType.deployment]
  },
  { 
    to: '/products', name: 'product', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin]
  },
  { 
    to: '/badges', name: 'badge', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.deployment ]
  },
  { 
    to: '/badges_category', name: 'badges category', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.deployment]
  },
  { 
    to: '/settings/rostershift', name: 'roster shift', exact: true, Icon: MdWeb,
    role: [RoleType.facility, RoleType.superadmin]
  },
  { 
    to: '/settings/rostertask', name: 'roster task', exact: true, Icon: MdWeb,
    role: [RoleType.facility, RoleType.superadmin]
  },
  { to: '/zone', name: 'zone', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.facility, RoleType.homecare]
  },
  { to: '/medical_condition/setting', name: 'Medical condition setting', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin]
  },
  { to: '/facility_room_config', name: 'room', exact: false, Icon: MdStar,
    role: [RoleType.superadmin]
  },
]

const orderSubMenu = [
  {
    to: '/orders/active', name: 'active', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance]
  },
  {
    to: '/orders/unpaid', name: 'unpaid', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance]
  },
  { 
    to: '/orders/completed', name: 'completed', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] 
  },
  { 
    to: '/orders/cancelled', name: 'cancelled', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] 
  },
  { 
    to: '/orders/all', name: 'all', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] 
  },
];

const facilityOrderSubMenu = [
  { 
    to: '/facility_orders/active', name: 'active', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate]
  },
  { 
    to: '/facility_orders/unpaid', name: 'unpaid', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate]
  },
  { 
    to: '/facility_orders/completed', name: 'completed', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] 
  },
  { 
    to: '/facility_orders/cancelled', name: 'cancelled', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] 
  },
  { to: '/facility_orders/all', name: 'all', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] 
  },
];

const careProSubMenu = [
  { to: '/caregivers-new', name: 'New', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-pending_verification', name: 'Pending Verification', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-active', name: 'Active', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-kiv', name: 'Assessment KIV', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-blacklisted', name: 'Blacklisted', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-disabled', name: 'Disabled', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers-all', name: 'All', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
]

const facilityCareProSubmenu = [
  { to: '/facility_caregiver-new', name: 'New', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-pending_verification', name: 'Pending Verification', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-active', name: 'Active', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-kiv', name: 'Assessment KIV', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-blacklisted', name: 'Blacklisted', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-disabled', name: 'Disabled', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
  { to: '/facility_caregiver-all', name: 'All', exact: false, Icon: MdWeb,
  role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] },
]

const navAppointments = [
  { to: '/apps/create', name: 'create', exact: false, Icon: MdTextFields },
  { to: '/jobs/create', name: 'create job', exact: false, Icon: MdBorderAll },
  { to: '/jobs/interest', name: 'job interest', exact: false, Icon: MdBorderAll },
  { to: '/timesheets', name: 'timesheet', exact: false, Icon: MdBorderAll },
];

const jobSubMenu = [
  { to: '/jobs/new', name: 'Create New', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-draft', name: 'Draft', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-open', name: 'Open', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-inprogress', name: 'In Progress', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-completed', name: 'Completed', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-cancelled', name: 'Cancelled', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-closed', name: 'Closed', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/jobs-all', name: 'All', exact: false, Icon: MdWidgets,
  role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
];

const navHomeCare =[
  { to: '/homecare-dashboard', name: 'Dashboard', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.homecare] },
  { to: '/customers', name: 'clients', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/issues', name: 'Issues', exact: false, Icon: MdOutlineSimCardAlert,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance] },
  { to: '/caregivers', name: 'care pro', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance], 
    submenu: careProSubMenu},
  { to: '/orders', name: 'orders', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance], 
    submenu: orderSubMenu},
  {
    to: '/salary-listing', name: 'Salary', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.corporate, RoleType.finance, RoleType.homecare]
  },
  { to: '/jobs', name: 'jobs', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.homecare, RoleType.corporate, RoleType.finance],
    submenu: jobSubMenu },
  { to: '/work_experience/list/kL5iVa7a', name: 'work experience', exact: true, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.facility, RoleType.homecare]
  },
  {
    to: '/ancillary_listing', name: 'Ancillary Service', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.corporate, RoleType.finance, RoleType.homecare]
  },
];

const navFacilityMgmt = [
  { to: '/facility_dashboard', name: 'dashboard', exact: true, Icon: MdDashboard,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate] 
  },
  { to: '/facility_customer', name: 'clients', exact: true, Icon: MdPeople,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate] 
  },
  { 
    to: '/issues', name: 'Issues', exact: false, Icon: MdOutlineSimCardAlert,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate, RoleType.finance] 
  },
  { to: '/facility_caregiver', name: 'care pro', exact: false, Icon: MdPeople,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate],
    submenu: facilityCareProSubmenu
  },
  { to: '/facility_analytics', name: 'analytics', exact: false, Icon: MdRadioButtonChecked,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_orders/all', name: 'orders', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate],
    submenu: facilityOrderSubMenu
  },
  { to: '/facility_billing', name: 'billing', exact: false, Icon: MdGroupWork,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate]
  },
  { to: '/facility_inventory', name: 'inventory', exact: false, Icon: MdChromeReaderMode,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_maintenance', name: 'maintenance', exact: false, Icon: MdViewList,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_panic_dashboard', name: 'panic dashboard', exact: false, Icon: BsBellFill,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_rostering', name: 'rostering', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_work_experience/list/Mfpcq69W', name: 'work experience', exact: true, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/call_alert_config', name: 'call alert config', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_room_config', name: 'room', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.corporate, RoleType.finance, RoleType.facility]
  },
  {
    to: '/ancillary_listing', name: 'Ancillary Service', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.corporate, RoleType.finance, RoleType.facility]
  },
];

const navFacilityMgmtNoDashboard = [
  { to: '/facility_dashboard', name: 'dashboard', exact: true, Icon: MdDashboard,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate] 
  },
  { to: '/facility_customer', name: 'clients', exact: true, Icon: MdPeople,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate], 
  },
  { to: '/facility_caregiver', name: 'care pro', exact: false, Icon: MdRadioButtonChecked,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate],
    submenu: facilityCareProSubmenu
  },
  { to: '/facility_analytics', name: 'analytics', exact: false, Icon: MdRadioButtonChecked,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_orders/all', name: 'orders', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate],
    submenu: facilityOrderSubMenu
  },
  { to: '/facility_billing', name: 'billing', exact: false, Icon: MdGroupWork,
    role: [RoleType.superadmin, RoleType.facility, RoleType.finance, RoleType.corporate]
  },
  { to: '/facility_inventory', name: 'inventory', exact: false, Icon: MdChromeReaderMode,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_maintenance', name: 'maintenance', exact: false, Icon: MdViewList,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_rostering', name: 'rostering', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_work_experience/list/Mfpcq69W', name: 'work experience', exact: true, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/call_alert_config', name: 'call alert config', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  { to: '/facility_room_config', name: 'room', exact: false, Icon: MdStar,
    role: [RoleType.superadmin, RoleType.facility, RoleType.corporate]
  },
  {
    to: '/ancillary_listing', name: 'Ancillary Service', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.corporate, RoleType.finance, RoleType.facility]
  }
];

const navDeployment =[
  { to: '/deployment_customer', name: 'clients', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.deployment] },
  { to: '/caregivers-deployment', name: 'care pro', exact: false, Icon: MdWeb,
    role: [RoleType.superadmin, RoleType.deployment], 
    submenu: [
      { to: '/deployment_caregiver-new', name: 'New', exact: false, Icon: MdWeb,
        role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-pending_verification', name: 'Pending Verification', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-active', name: 'Active', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-kiv', name: 'Assessment KIV', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-blacklisted', name: 'Blacklisted', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-disabled', name: 'Disabled', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-available', name: 'Available', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-on_duty', name: 'On Duty', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/deployment_caregiver-all', name: 'All', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment] },
    ]},
  { to: '/orders-deployment', name: 'orders', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.deployment], 
    submenu: [
      { to: '/facility_orders/active', name: 'active', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment]},
      { to: '/facility_orders/unpaid', name: 'unpaid', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment]},
      { to: '/facility_orders/completed', name: 'completed', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/facility_orders/cancelled', name: 'cancelled', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/facility_orders/all', name: 'all', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment] },
    ]},
  { to: '/jobs-deployment', name: 'jobs', exact: false, Icon: MdWidgets,
    role: [RoleType.superadmin, RoleType.deployment],
    submenu: [
      { to: '/jobs/new', name: 'Create New', exact: false, Icon: MdWidgets,
        role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-draft', name: 'Draft', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-open', name: 'Open', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-inprogress', name: 'In Progress', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-completed', name: 'Completed', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-cancelled', name: 'Cancelled', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-closed', name: 'Closed', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
      { to: '/jobs-all', name: 'All', exact: false, Icon: MdWidgets,
      role: [RoleType.superadmin, RoleType.deployment] },
    ] },
    { 
      to: '/badges', name: 'badge', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment]
    },
    { 
      to: '/badges_category', name: 'badges category', exact: false, Icon: MdWeb,
      role: [RoleType.superadmin, RoleType.deployment]
    },
];
const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenAppointment: true,
    selectedNavItems: navItems,
    isOpenFacilityMgmt: false,
    facilityMgmtNoDashboardItems: navFacilityMgmtNoDashboard,
    isOpenHomeCare: false,
    isOpenSubmenu: {}, // Initialize isOpenSubmenu as an empty object
    // isOpenAppointments: true,
    // isOpenPages: true,
  };

  componentDidUpdate(previousProps, previousState) {
    if(previousProps.isShowSetting !== this.props.isShowSetting){
      this.setState({
        selectedNavItems: (this.props.isShowSetting ? navSettingItems : navItems)
      })
    }
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  toggleCollapse = (item) => {
    this.setState((prevState) => ({
      isOpenSubmenu: {
        ...prevState.isOpenSubmenu,
        [item.to]: !prevState.isOpenSubmenu[item.to],
      },
    }));
  };

 render() {
  const { isOpenHomeCare, isOpenSubmenu } = this.state;
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            {/* <SourceLink className="navbar-brand d-flex"> */}
              {/* <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              /> */}
              <span className="text-white" style={{textAlign: 'center'}}>
                CARE Guardian Portal {/* Reduction <FaGithub /> */}
                version {packageJson.version}
              </span>
            {/* </SourceLink> */}
          </Navbar>
          <ProfileContext.Consumer>
            { pContext => (
              <AuthContext.Consumer>
                {
                  prop => {
                    return (
                    <Nav vertical>
                      {!prop.isAuthenticated && 
                        <NavItem className={bem.e('nav-item')}>
                          <BSNavLink
                            id={`navItem-login`}
                            className="text-uppercase"
                            tag={NavLink}
                            to="/login"
                            activeClassName="active"
                            exact={false}
                          >
                            <MdLockOpen className={bem.e('nav-item-icon')} />
                            <span className="">login</span>
                          </BSNavLink>
                        </NavItem>
                      }
                      {
                        (prop.isAuthenticated && this.props.isShowSetting) && 
                        // <div className="font-weight-bold"><IoIosArrowBack size={24} /> Back</div>
                        <NavItem className={bem.e('nav-item')} onClick={this.props.setSetting}>
                          <BSNavLink
                            id={`navItem-back`}
                            className="text-uppercase"
                            to="/"
                          >
                            <IoMdArrowBack size={24} className={bem.e('nav-item-icon')} />
                            <span> Back</span>
                          </BSNavLink>
                        </NavItem>
                      }
                      {
                        // selectedNavItems = sidebar menu || setting menu
                        this.props.isShowSetting ?
                          prop.isAuthenticated && this.state.selectedNavItems.map(({ to, name, exact, Icon, role }, index) => (
                              role.includes(pContext.profile.type) && 
                              <NavItem key={index} className={bem.e('nav-item')}>
                                <BSNavLink
                                  id={`navItem-${name}-${index}`}
                                  className="text-uppercase"
                                  tag={NavLink}
                                  to={to}
                                  activeClassName="active"
                                  exact={exact}
                                >
                                  <Icon className={bem.e('nav-item-icon')} />
                                  <span className="">{name}</span>
                                </BSNavLink>
                              </NavItem>
                          )) :
                          prop.isAuthenticated && this.state.selectedNavItems.map(({ to, name, exact, Icon, role, showDashboard }, index) => (
                            
                            role.includes(pContext.profile.type) && pContext.profile.dashboard === showDashboard &&
                            <NavItem key={index} className={bem.e('nav-item')}>
                              <BSNavLink
                                id={`navItem-${name}-${index}`}
                                className="text-uppercase"
                                tag={NavLink}
                                to={to}
                                activeClassName="active"
                                exact={exact}
                              >
                                <Icon className={bem.e('nav-item-icon')} />
                                <span className="">{name}</span>
                              </BSNavLink>
                            </NavItem>
                        ))
                      }
                      {
                        // Homecare Tab
                        (prop.isAuthenticated && !this.props.isShowSetting && 
                          (pContext.profile.type == RoleType.superadmin ||
                            pContext.profile.type == RoleType.homecare ||
                            pContext.profile.type == RoleType.corporate ||
                            pContext.profile.type == RoleType.finance
                            )
                           ) &&
                        <NavItem
                            className={bem.e('nav-item')}
                            onClick={this.handleClick('HomeCare')}
                          >
                            <BSNavLink className={bem.e('nav-item-collapse')}>
                              <div className="d-flex">
                                <MdExtension className={bem.e('nav-item-icon')} />
                                <span className="text-uppercase">{pContext.profile.type == RoleType.finance? "finance" : "homecare"}</span>
                              </div>
                              <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={{
                                  padding: 0,
                                  transform: this.state.isOpenHomeCare
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                  transitionDuration: '0.3s',
                                  transitionProperty: 'transform',
                                }}
                              />
                            </BSNavLink>
                        </NavItem>
                      }
                      {
                        prop.isAuthenticated && !this.props.isShowSetting && (
                          <Collapse isOpen={isOpenHomeCare}>                          
                            <MenuGroup 
                              menuGroupName={'homecare-menu'}
                              navList={navHomeCare}
                              isOpenSubmenu={isOpenSubmenu}
                              pContext={pContext}
                              toggleCollapse={this.toggleCollapse}
                            />
                          </Collapse>
                        )
                      }
                      {
                        // Facility Management Tab
                        (prop.isAuthenticated && !this.props.isShowSetting && 
                          (pContext.profile.type == RoleType.superadmin ||
                            pContext.profile.type == RoleType.facility ||
                            pContext.profile.type == RoleType.corporate
                            )
                           ) &&
                        <NavItem
                            className={bem.e('nav-item')}
                            onClick={this.handleClick('FacilityMgmt')}
                          >
                            <BSNavLink className={bem.e('nav-item-collapse')}>
                              <div className="d-flex">
                                <MdExtension className={bem.e('nav-item-icon')} />
                                <span className="text-uppercase">Residence CARE</span>
                              </div>
                              <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={{
                                  padding: 0,
                                  transform: this.state.isOpenFacilityMgmt
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                  transitionDuration: '0.3s',
                                  transitionProperty: 'transform',
                                }}
                              />
                            </BSNavLink>
                        </NavItem>
                      }
                      {
                        // Facility Management Menu
                        (prop.isAuthenticated && !this.props.isShowSetting) &&
                        <Collapse isOpen={this.state.isOpenFacilityMgmt}>
                          {
                            pContext.profile.dashboard === 1 ?
                            <div>
                              <MenuGroup 
                                menuGroupName={'facility-management-menu'}
                                navList={navFacilityMgmt}
                                isOpenSubmenu={isOpenSubmenu}
                                pContext={pContext}
                                toggleCollapse={this.toggleCollapse}
                              />
                            </div> : 
                            <div>
                              <MenuGroup 
                                menuGroupName={'facility-no-dashboard-menu'}
                                navList={navFacilityMgmtNoDashboard}
                                isOpenSubmenu={isOpenSubmenu}
                                pContext={pContext}
                                toggleCollapse={this.toggleCollapse}
                              />
                            </div>
                          }
                        </Collapse>
                      }
                      
                      {
                        // Deployment Tab
                        (prop.isAuthenticated && !this.props.isShowSetting && 
                          (pContext.profile.type == RoleType.superadmin ||
                            pContext.profile.type == RoleType.deployment
                            )
                           ) &&
                        <NavItem
                            className={bem.e('nav-item')}
                            onClick={this.handleClick('Deployment')}
                          >
                            <BSNavLink className={bem.e('nav-item-collapse')}>
                              <div className="d-flex">
                                <MdExtension className={bem.e('nav-item-icon')} />
                                <span className="text-uppercase">Deployment Menu</span>
                              </div>
                              <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={{
                                  padding: 0,
                                  transform: this.state.isOpenDeployment
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                  transitionDuration: '0.3s',
                                  transitionProperty: 'transform',
                                }}
                              />
                            </BSNavLink>
                        </NavItem>
                      }
                      {
                        // Deployment Menu
                        (prop.isAuthenticated && !this.props.isShowSetting) &&
                        <Collapse isOpen={this.state.isOpenDeployment}>
                          {
                              <MenuGroup 
                                menuGroupName={'deployment-management-menu'}
                                navList={navDeployment}
                                isOpenSubmenu={isOpenSubmenu}
                                pContext={pContext}
                                toggleCollapse={this.toggleCollapse}
                              />
                          }
                        </Collapse>
                      }
                      {/* {prop.isAuthenticated && 
                        <>
                          <NavItem
                              className={bem.e('nav-item')}
                              onClick={this.handleClick('Appointments')}
                            >
                            <BSNavLink className={bem.e('nav-item-collapse')}>
                              <div className="d-flex">
                                <MdSend className={bem.e('nav-item-icon')} />
                                <span className="">Appointments</span>
                              </div>
                              <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={{
                                  padding: 0,
                                  transform: this.state.isOpenAppointments
                                    ? 'rotate(0deg)'
                                    : 'rotate(-90deg)',
                                  transitionDuration: '0.3s',
                                  transitionProperty: 'transform',
                                }}
                              />
                            </BSNavLink>
                          </NavItem>
                          <Collapse isOpen={this.state.isOpenAppointments}>
                            {navAppointments.map(({ to, name, exact, Icon }, index) => (
                              <NavItem key={index} className={bem.e('nav-item')}>
                                <BSNavLink
                                  id={`navItem-${name}-${index}`}
                                  className="text-uppercase"
                                  tag={NavLink}
                                  to={to}
                                  activeClassName="active"
                                  exact={exact}
                                >
                                  <Icon className={bem.e('nav-item-icon')} />
                                  <span className="">{name}</span>
                                </BSNavLink>
                              </NavItem>
                            ))}
                          </Collapse>
                        </>
                      } */}
          
                      {/* <NavItem
                        className={bem.e('nav-item')}
                        onClick={this.handleClick('Pages')}
                      >
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                          <div className="d-flex">
                            <MdPages className={bem.e('nav-item-icon')} />
                            <span className="">Pages</span>
                          </div>
                          <MdKeyboardArrowDown
                            className={bem.e('nav-item-icon')}
                            style={{
                              padding: 0,
                              transform: this.state.isOpenPages
                                ? 'rotate(0deg)'
                                : 'rotate(-90deg)',
                              transitionDuration: '0.3s',
                              transitionProperty: 'transform',
                            }}
                          />
                        </BSNavLink>
                      </NavItem>
                      <Collapse isOpen={this.state.isOpenPages}>
                        {pageContents.map(({ to, name, exact, Icon }, index) => (
                          <NavItem key={index} className={bem.e('nav-item')}>
                            <BSNavLink
                              id={`navItem-${name}-${index}`}
                              className="text-uppercase"
                              tag={NavLink}
                              to={to}
                              activeClassName="active"
                              exact={exact}
                            >
                              <Icon className={bem.e('nav-item-icon')} />
                              <span className="">{name}</span>
                            </BSNavLink>
                          </NavItem>
                        ))}
                      </Collapse> */}
                    </Nav>
                  )}
                }
              </AuthContext.Consumer>
            )}
          </ProfileContext.Consumer>
        </div>
      </aside>
    );
  }
}


const MenuGroup = ({menuGroupName, navList, isOpenSubmenu, pContext, toggleCollapse}) => {
  return <>{navList.map((item, index) => {
    const { to, name, exact, Icon, role, submenu } = item;
    const showSubmenu = isOpenSubmenu[to];

    if (role.includes(pContext.profile.type)) {
      return (
        <NavItem key={`${index}-${menuGroupName}`} className={bem.e('nav-item')}>
          {submenu ? (
            <>
              <NavItem className={bem.e('nav-item')} style={{ margin: 0 }} onClick={() => toggleCollapse(item)}>
                <BSNavLink
                  id={`navItem-${name}-${menuGroupName}-${index}`}
                  className={bem.e('nav-item-collapse')}
                  activeClassName="active"
                  exact={exact}
                >
                  <div className="d-flex">
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="text-uppercase">{name}</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: isOpenSubmenu[to]
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={showSubmenu}>
                {submenu.map((subItem, subIndex) => (
                  subItem.role.includes(pContext.profile.type) && (
                    <BSNavLink
                      style={{ margin: '5px 15px' }}
                      key={subIndex}
                      id={`navItem-${subItem.name}-${menuGroupName}-${subIndex}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={subItem.to}
                      activeClassName="active"
                      exact={subItem.exact}
                    >
                      <subItem.Icon className={bem.e('nav-item-icon')} />
                      <span>{subItem.name}</span>
                    </BSNavLink>
                  )
                ))}
              </Collapse>
            </>
          ) : (
            <BSNavLink
              id={`navItem-${name}-${index}`}
              className="text-uppercase"
              tag={NavLink}
              to={to}
              activeClassName="active"
              exact={exact}
            >
              <Icon className={bem.e('nav-item-icon')} />
              <span>{name}</span>
            </BSNavLink>
          )}
        </NavItem>
      );
    }
    return null;
  })}</>
}

export default Sidebar;
